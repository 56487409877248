const styles = (theme) => ({
  popup: {
    width: '100%',
    ' & .MuiDialog-paper': {
      maxWidth: '700px',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        margin: '15px',
      },
    },
  },
  closeOuter: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'flex-end',
    margin: 10,
  },
  closeInner: {
    padding: 10,
  },
  dialogContent: {
    maxWidth: '700px',
    minWidth: '600px',
    width: '100%',
    padding: '0 !important',

    [theme.breakpoints.down('xs')]: {
      minWidth: '100%',
    },
  },
  contentWrap: {
    padding: '0 24px 24px',
  },
  item: {
    padding: '5px',
    borderRadius: '5px',
    boxShadow: 'rgba(0, 0, 0, 0.15) 2px 3px 10px 0px',
  },
  benefitItem: {
    display: 'flex',
    alignItems: 'center',
  },
  checkIcon: {
    color: theme.palette.primary.main,
    marginRight: '5px',
  },
  offIcon: {
    color: '#dfdfdf',
    marginRight: '5px',
  },
  benefitName: {
    fontSize: '14px',
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: '24px',
    fontWeight: '500',
    textAlign: 'center',
    paddingBottom: '20px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '20px',
    },
  },
  planTitle: {
    color: theme.palette.primary.main,
    fontSize: '20px',
    fontWeight: '500',
    textAlign: 'center',
    marginTop: '10px',
  },
  price: {
    textAlign: 'center',
    paddingBottom: '10px',
  },
  benefits: {
    margin: '10px 0 10px 20px',
  },
  goToBtn: {
    textTransform: 'none',
    padding: '8px 16px',
    background: theme.palette.primary.main,
    color: 'white',
    '&:hover': {
      background: theme.palette.secondary.main,
    },
  },
  actionsGroup: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '15px',
  },
  duration: {
    fontSize: '10px',
  },
});

export default styles;
