import React from 'react';
import PropTypes from 'prop-types';

import Link from 'next/link';

import styles from './styles';
import { makeStyles } from '@material-ui/core/styles';
import { planBenefits } from 'common/constants';

import {
  Button,
  Dialog,
  DialogContent,
  Divider,
  Grid,
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CloseIcon from '@material-ui/icons/Close';
import { identity } from 'ramda';

const useStyles = makeStyles(styles);

const ForceUserSubscribe = (props) => {
  const classes = useStyles();
  const { isOpen, onClose = identity } = props;

  const preventClick = (evt) => {
    evt.stopPropagation();
    evt.preventDefault();
  };

  return (
    <Dialog
      className={classes.popup}
      onClick={preventClick}
      aria-labelledby="customized-dialog-title"
      open={isOpen}
    >
      <div className={classes.closeOuter}>
        <div className={classes.closeInner} onClick={onClose}>
          <CloseIcon />
        </div>
      </div>

      <DialogContent className={classes.dialogContent}>
        <div className={classes.contentWrap}>
          <div className={classes.title}>Subscribe to continue with us</div>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6} sm={6}>
              <div className={classes.item}>
                <div className={classes.planTitle}>Basic Membership</div>
                <div className={classes.price}>
                  <sup>$</sup>28/
                  <span className={classes.duration}>month</span> or{' '}
                  <sup>$</sup>220/
                  <span className={classes.duration}>year</span>
                </div>
                <Divider />
                <div className={classes.benefits}>
                  {planBenefits.map((item, index) => (
                    <div key={index} className={classes.benefitItem}>
                      <Choose>
                        <When condition={item.basic}>
                          <CheckCircleIcon className={classes.checkIcon} />
                        </When>
                        <Otherwise>
                          <HighlightOffIcon className={classes.offIcon} />
                        </Otherwise>
                      </Choose>
                      <div className={classes.benefitName}>{item.name}</div>
                    </div>
                  ))}
                </div>
              </div>
            </Grid>
            <Grid item xs={12} lg={6} sm={6}>
              <div className={classes.item}>
                <div className={classes.planTitle}>Premium Membership</div>
                <div className={classes.price}>
                  <sup>$</sup>46/
                  <span className={classes.duration}>month</span> or{' '}
                  <sup>$</sup>340/
                  <span className={classes.duration}>year</span>
                </div>
                <Divider />
                <div className={classes.benefits}>
                  {planBenefits.map((item, index) => (
                    <div key={index} className={classes.benefitItem}>
                      <Choose>
                        <When condition={item.premium}>
                          <CheckCircleIcon className={classes.checkIcon} />
                        </When>
                        <Otherwise>
                          <HighlightOffIcon className={classes.offIcon} />
                        </Otherwise>
                      </Choose>
                      <div className={classes.benefitName}>{item.name}</div>
                    </div>
                  ))}
                </div>
              </div>
            </Grid>
          </Grid>
          <div className={classes.actionsGroup}>
            <Link href="/account/subscription" legacyBehavior>
              <Button autoFocus className={classes.goToBtn}>
                Subscribe Now
              </Button>
            </Link>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

ForceUserSubscribe.defaultProps = {
  isOpen: false,
  onClose: () => null,
};

ForceUserSubscribe.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default ForceUserSubscribe;
